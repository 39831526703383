// core
import React, { useEffect, useCallback } from 'react';

// libraries
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

// assets
import ogImage from '../../images/og-home.png';
import banner from '../../images/homepage_banner.png';
import image1 from '../../images/homepage_edenbazar.jpg';
// import image2 from '../../images/about_edenbazar_1.png';

// components
import { ContentWrap } from 'components/ContentWrap';
import { PageHeading } from 'components/PageHeading';

// material
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import UILink from '@material-ui/core/Link';

// material icons
import PhonetIcon from '@material-ui/icons/PhoneOutlined';
import CheckIcon from '@material-ui/icons/CheckCircleOutlined';

// partials
import { Advantages } from './components/Advantages';
import { Reviews } from './components/Reviews';
import { Categories } from './components/Categories';
import { NewestCars } from './components/NewestCars';
import { ContactForm } from '../Contact/components/ContactForm';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 64
  },
  row: {
    position: 'relative',
    marginBottom: 128,
    '&:last-child': {
      marginBottom: 0
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 96
    }
  },
  intro: {
    minHeight: 534,
    marginRight: -40,
    background: `url('${banner}')`,
    backgroundPosition: 'top right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 100%',
    '& > h2': {
      marginTop: 24
    },
    [theme.breakpoints.down('md')]: {
      marginRight: -24,
      paddingRight: 24,
      minHeight: 500,
      backgroundPosition: '180px 0'
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 490
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: -16,
      paddingRight: 16,
      minHeight: 410,
      backgroundPosition: '100px 0'
    }
  },
  introBox: {
    width: 550,
    maxWidth: '100%',
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 32,
    paddingRight: 32,
    border: 'solid 1px',
    borderColor: theme.borderColor,
    borderRadius: 4,
    backgroundColor: '#fff',
    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.1)',
    '& > ul': {
      listStyle: 'none',
      padding: 0,
      paddingTop: 8,
      '& li': {
        marginBottom: 8,
        lineHeight: '1.4em',
        paddingLeft: 36,
        position: 'relative',
        '& svg': {
          position: 'absolute',
          top: 0,
          left: 0,
          color: theme.palette.primary.main
        }
      },
      '& span': {
        marginTop: -18,
        marginBottom: 8,
        marginLeft: 54,
        [theme.breakpoints.down('sm')]: {
          marginTop: 4
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 24,
      paddingRight: 24
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 16,
      paddingRight: 16
    }
  },
  introBoxAddon: {
    marginTop: 16,
    marginLeft: 40,
    fontWeight: 'normal',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 32
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 24,
      paddingRight: '15%'
    }
  },
  /* listColumns: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 16,
    '& > div': {
      flex: 3
    },
    '& > div:last-child': {
      flex: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        '& > span': {
          marginBottom: 4
        }
      }
    }
  }, */
  mainHeading: {
    paddingTop: 96,
    marginBottom: 16,
    paddingBottom: 0,
    '& h1': {
      fontSize: '3em',
      textShadow: '0 0 5px #fff',
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.5em'
      }
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 64
    }
  },
  subheading: {
    fontWeight: 'bold',
    marginBottom: 24,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.8em'
    }
  },
  mainHeadingDesc: {
    textShadow: '0 0 3px #fff'
  },
  imageWithBox: {
    marginTop: 32,
    marginLeft: 32,
    position: 'relative',
    '& > img': {
      width: '100%',
      height: 'auto',
      borderRadius: 4
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: -32,
      left: -32,
      width: '100%',
      height: '100%',
      backgroundColor: theme.backgroundColor,
      borderRadius: 4,
      zIndex: -1
    }
  },
  about: {
    '& > div': {
      '& > div > a': {
        marginTop: 24
      },
      '& > div > h2': {
        marginTop: 16,
        [theme.breakpoints.down('xs')]: {
          marginTop: 32
        }
      }
    },
    '& > div:first-child > div:first-child': {
      paddingRight: '64px !important',
      [theme.breakpoints.down('sm')]: {
        paddingRight: '0 !important'
      }
    }
  },
  reverse: {
    '& > div:first-child': {
      flexDirection: 'row-reverse'
    },
    '& > div:first-child > div:first-child': {
      paddingRight: '0 !important'
    },
    '& > div:first-child > div:last-child': {
      paddingRight: '64px !important',
      [theme.breakpoints.down('sm')]: {
        paddingRight: '0 !important'
      }
    }
  },
  categoriesButton: {
    marginTop: 32,
    textAlign: 'center'
  },
  contactForm: {
    backgroundColor: theme.backgroundColor,
    padding: 32,
    paddingTop: 48,
    borderRadius: 4,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 16,
      paddingRight: 16
    }
  },
  contactFormHeading: {
    paddingBottom: 16
  },
  contactFormText: {
    marginBottom: 24
  },
  phoneWrap: {
    marginTop: 48,
    marginBottom: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  phone: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    fontSize: '2em',
    whiteSpace: 'nowrap',
    '& > svg': {
      marginRight: 4
    },
    '& > a': {
      fontSize: 'inherit'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5em'
    }
  },
  superSocoImage: {
    marginTop: -100,
    marginBottom: -80,
    marginLeft: -80,
    width: '120%',
    height: 'auto'
  }
}));

export const Home = () => {
  const classes = useStyles();
  const sendDataToGTM = useGTMDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePhoneLinkClick = useCallback(() => {
    sendDataToGTM({
      event: 'event',
      eventCategory: 'PhoneLink',
      eventAction: 'Click',
      eventValue: undefined,
      eventLabel: 'Home'
    });
  }, [sendDataToGTM]);

  return (
    <>
      <Helmet>
        <title>EDENbazar - Autá so zárukou</title>
        <meta
          name="description"
          content="Autobazár v Bratislave s ponukou málo jazdených ale aj nových vozidiel, ktoré spĺňajú naše prísne kritéria na kvalitu. Vozidlá majú 100% overiteľnú históriu a pôvod a boli servisované v autorizovaných servisoch."
        />
        <meta property="og:url" content={`${global.baseUrl}`} />
        <meta property="og:title" content="EDENbazar - Autá so zárukou" />
        <meta
          property="og:description"
          content="Autobazár v Bratislave s ponukou málo jazdených ale aj nových vozidiel, ktoré spĺňajú naše prísne kritéria na kvalitu. Vozidlá majú 100% overiteľnú históriu a pôvod a boli servisované v autorizovaných servisoch."
        />
        <meta
          property="og:image"
          content={`${global.baseUrl.replace('https', 'http')}${ogImage}`}
        />
        <meta property="og:image:secure_url" content={`${global.baseUrl}${ogImage}`} />
      </Helmet>

      <ContentWrap className={classes.root}>
        <div className={classnames(classes.row, classes.intro)}>
          <PageHeading className={classes.mainHeading}>Autá so zárukou</PageHeading>
          <Typography variant="subtitle1" component="p" className={classes.mainHeadingDesc}>
            Ponúkame jazdené vozidlá vo výbornom stave.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            U nás nájdete
          </Typography>
          <div className={classes.introBox}>
            <Typography variant="subtitle1" color="secondary" component="ul">
              <li>
                <CheckIcon />
                málo jazdené - do 2 rokov
              </li>
              <li>
                <CheckIcon />
                po prvom majiteľovi
              </li>
              <li>
                <CheckIcon />
                ešte vo fabrickej záruke
              </li>
              <li>
                <CheckIcon />s dokladovateľnou históriou servisu a pôvodu
              </li>
              <li>
                <CheckIcon />
                alebo <strong>úplne nové</strong>
              </li>
            </Typography>
          </div>
          <Typography variant="subtitle2" component="p" className={classes.introBoxAddon}>
            ... máme pre Vás ideálne vozidlo{' '}
            <UILink component={Link} to="/vozidla-na-predaj">
              v našej ponuke
            </UILink>
            .
          </Typography>
        </div>

        <div className={classnames(classes.row, classes.about, classes.reverse)}>
          <Box clone paddingBottom={4}>
            <Typography variant="h4" component="h2" className={classes.subheading}>
              Najnovšie vozidlá v ponuke
            </Typography>
          </Box>

          <NewestCars />

          <div className={classes.categoriesButton}>
            <Button variant="contained" color="primary" component={Link} to="/vozidla-na-predaj">
              Zobratiť celú ponuku
            </Button>
          </div>
        </div>

        <div className={classnames(classes.row, classes.about)}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6} lg={5}>
              <div className={classes.imageWithBox}>
                <img src={image1} alt="EDENbazar" width="900" height="600" />
              </div>
            </Grid>

            <Grid item xs={12} md={6} lg={7}>
              <Typography variant="h4" component="h2" className={classes.subheading}>
                Predaj jazdených vozidiel
              </Typography>

              <Typography variant="subtitle1" component="p" gutterBottom>
                Odpredávame vozidlá z nášho operatívneho leasingu / dlhodobého nájmu. Autá{' '}
                <strong>kupujeme na Slovensku</strong> ako <strong>úplne nové</strong> a{' '}
                <strong>po roku ich dávame na odpredaj</strong>. Prvý rok klesá najviac hodnota
                vozidla, preto naše autá majú veľmi <strong>priaznivé ceny</strong> a sú vo veľmi{' '}
                <strong>dobrom stave</strong>. Stále sú <strong>vo fabrickej záruke</strong> a teda
                nič neriskujte ani Vy, ale ani my.
              </Typography>

              <Button variant="contained" color="primary" component={Link} to="/o-nas">
                Prečítajte si viac o nás
              </Button>
            </Grid>
          </Grid>

          <Box marginTop={6}>
            <Categories />
          </Box>

          <div className={classes.categoriesButton}>
            <Button variant="contained" color="primary" component={Link} to="/vozidla-na-predaj">
              Všetky vozidlá
            </Button>
          </div>
        </div>

        {/* <div className={classnames(classes.row, classes.about)}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={6} lg={5}>
                <div className={classes.imageWithBox}>
                  <img src={image2} alt="EDENbazar" width="500" height="350" />
                </div>
              </Grid>

              <Grid item xs={12} md={6} lg={7}>
                <Typography variant="h4" component="h2" className={classes.subheading}>
                  Online predaj vozidiel
                </Typography>

                <Typography variant="subtitle1" component="p" gutterBottom>
                  Netreba sa toho báť, je to <strong>ako v iných e-shopoch</strong>. Vyberiete si
                  auto, zadáte dopyt, dohodneme sa na financovaní a poistení.{' '}
                  <strong>Všetko online</strong>. Vozidlo Vám následne privezieme domov. Ak sa Vám
                  nebude pozdávať, <strong>máte možnosť ho vrátiť</strong>.
                </Typography>

                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/online-predaj-vozidiel"
                >
                  Ďalsie informácie
                </Button>
              </Grid>
            </Grid>
          </div> */}

        {/* <div className={classes.row}>
          <PageHeading component="h2">Super SOCO</PageHeading>

          <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
              <img
                src={`${global.cdnUrl}/super-soco/super-soco-2.png`}
                alt="Super SOCO"
                className={classes.superSocoImage}
                width="1200"
                height="900"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="h2" className={classes.subheading}>
                Elektrické skútre a motorky
              </Typography>

              <Typography variant="subtitle1" component="p" gutterBottom>
                Naša spoločnosť sa stala autorizovaným predajcom{' '}
                <strong>elektrických motoriek a skútrov značky Super SOCO</strong>. Okrem kúpy si
                môžete tieto elektrické skútre a motorky u nás <strong>vyskúšať</strong> alebo
                dokonca <strong>na pár dní prenajať</strong>. Pokiaľ Vám tento spôsob prepravy bude
                vyhovovať, môžete si vybrať z aktuálnych skladových modelov alebo si elektro skúter
                alebo motorku <strong>objednať vo vami preferovanej farbe</strong>.
              </Typography>

              <Box clone marginTop={2}>
                <Button variant="contained" color="primary" component={Link} to="/super-soco">
                  Ďalsie informácie
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div> */}

        <div className={classes.row} data-test="advantages">
          <Advantages />
        </div>

        <div className={classes.row}>
          <PageHeading component="h2" className={classes.contactFormHeading}>
            Čo o nás hovoria zákazníci
          </PageHeading>

          <Reviews />
        </div>

        <div className={classes.row}>
          <div className={classes.contactForm}>
            <PageHeading component="h2" className={classes.contactFormHeading}>
              Dopytový formulár
            </PageHeading>

            <Typography variant="subtitle1" component="p" className={classes.contactFormText}>
              Dajte nám vedieť aké vozidlo hľadáte a my vám zašleme našu najlepšiu ponuku.
            </Typography>

            <ContactForm />
          </div>

          <div className={classes.phoneWrap}>
            <Typography variant="subtitle1" component="p">
              ... alebo nám zavolajte
            </Typography>

            <div className={classes.phone}>
              <PhonetIcon color="primary" fontSize="inherit" />

              <UILink
                variant="h6"
                component="a"
                href="tel:+421940509689"
                onClick={handlePhoneLinkClick}
              >
                +421 940 509 689
              </UILink>
            </div>

            <Typography variant="caption">
              Sme Vám k dispozícii v pracovných dňoch od 8:00 do 17:00
            </Typography>
          </div>
        </div>
      </ContentWrap>
    </>
  );
};
